import React from 'react';
import sb from '../../Services/StringBundleService';
import './Partners.css';

const Partners = ({}) => (
    <div className="Partners">
      <h1>OUR PARTNERS</h1>
      <div className="Desc">
        우리의 투자자들은 재무적인 것을 넘어 경험과 식견을 공유합니다.
      </div>
      <div className="Desc">

      </div>
    </div>
)

export default Partners;